import { FC } from 'react';
import { FAQSectionProps, IFAQItem, IFAQMainEntity, IFAQStructureData } from './types';
import { getParsedTextFromHTML } from '@libs/utils/article.utils';
import FaqItem from './faq-item';
import styles from './faq.module.scss';


const FAQSection: FC<FAQSectionProps> = (
  { title, subtitle, itemsFAQ, hoverColor, className, titleRef }
) => {
    const FAQStructureData: IFAQStructureData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: getMainEntitiesFAQ(itemsFAQ)
    }

    function getMainEntitiesFAQ(entities?: IFAQItem[]): IFAQMainEntity[] {
        if (!entities) {
            return [];
        }
        return entities.map(({ question, answers }) => ({
            '@type': 'Question',
            name: getParsedTextFromHTML(question),
            acceptedAnswer: {
                '@type': 'Answer',
                text: getParsedTextFromHTML(answers.join(' ')),
            }
        }));
    }

    return (
      <>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(FAQStructureData) }}/>
          <section className={`${styles.section} ${className ? className : ''}`}>
              <div className={styles.section__head}>
                  <h2 ref={titleRef} className={styles.section__title}>{title}</h2>
                  {subtitle ? <p className={styles.section__subtitle}>{subtitle}</p> : ''}
              </div>
              <div className={styles.section__questions}>
                  {itemsFAQ.map(({ question, answers }, idx, self) => {
                      const isLastElement: boolean = idx === self.length - 1;
                      return <FaqItem
                        key={question}
                        title={question}
                        answers={answers}
                        pointNumber={idx + 1}
                        isLastQuestion={isLastElement}
                        hoverColor={hoverColor}/>
                  })}
              </div>
          </section>
      </>
    )
}

export default FAQSection;
