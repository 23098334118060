import { FC, useState } from 'react';
import { FAQItemProps } from './types';
import Image from 'next/image';
import parse from 'html-react-parser';
import styles from './faq.module.scss';

const FAQItem: FC<FAQItemProps> = ({ title, pointNumber, answers, isLastQuestion, hoverColor }) => {
    const [opened, setOpened] = useState(false);

    const toggleOpenHandler = () => setOpened(prev => !prev);

    return (
      <div className={`${styles.block} ${isLastQuestion ? styles.block__last : ''}`}>
          <div className={`${styles.block__wrapper} ${styles[hoverColor]}`}>
              <div className={styles.block__question} onClick={toggleOpenHandler}>
                  <span className={styles.block__number}>{pointNumber}</span>
                  <h3 className={styles.block__title}>{title}</h3>
                  <div className={`${styles.block__icon} ${opened ? styles.opened : ''}`}>
                      <Image src={'/assets/icons/ic-plus.png'} alt={'FAQ'} layout={'fixed'} width={26} height={26}/>
                  </div>
              </div>
              {opened ? (
                <div className={styles.block__answer}>
                    {answers.map(answer => (
                      <div key={answer} className={styles.block__answer_item}>{parse(answer)}</div>
                    ))}
                </div>
              ) : ''}
          </div>
      </div>

    );
};

export default FAQItem;
